/**
 * Handles modals
 */

export default class modal {
    constructor() {
        // Handle include
        this.show = false;
        // Handle transition
        this.view = false;
        // Store html data
        this.html = null;

    }

}
