import {createApp} from 'petite-vue';
import search from "./services/search";
// Directives
import complete from "./directives/complete";
import lazy from "./directives/lazy";
import aside from "./directives/aside";
import editor from "./directives/editor";
import mounted from "./directives/mounted";

/**
 * Services
 */
import behave from "./services/behave";
import device from "./services/device";
import modal from "./services/modal";


window.touchHandler = ('ontouchstart' in document.documentElement ? "touchstart" : "click");
window.global = window.global || {};
window.service = window.location.protocol + "//api." + window.location.host;

window.app = createApp({
    device: new device(),
    behave: false,
    service: {
        ready: false,
    },
    search: {
        global: new search(window.global.search.url),
    },
    filter: {
        visible: false,
    },
    modal: {
        menu: new modal(),
    },
    mounted() {
        behave.device((o) => {
            this.device = new device();
            this.filter.visible = false;
        });
        behave.event(() => {
            this.behave = true;
        });
    },
});

app.directive('mounted', mounted);
app.directive('aside', aside);
app.directive('complete', complete);
app.directive('editor', editor);
app.directive('lazy', lazy);
app.mount();
