/**
 * This handles menus and drop downs
 */

export default {
    timeout: 0,
    changes: {
        'orientationchange': window,
        'resize': window,
    },
    event: (callback) => {

        let interacted = false;

        let available = {
            'orientationchange': window,
            'resize': window,
            'scroll': document,
            'mousemove': document,
            'keydown': document,
            'click': document,
            'touchstart': document,
        };

        let interacts = (event) => {
            // console.log(event.type);
            if ( interacted === false ) {
                for (const [key, value] of Object.entries(available)) {
                    value.removeEventListener(key, interacts);
                }
                if ( typeof callback !== "undefined") {
                    callback(event);
                }
                interacted = true;
            }
        };

        for (const [key, value] of Object.entries(available)) {
            value.addEventListener(key, interacts, { passive: true });
        }

    },
    /**
     * Trigger callback when resolution or orientation is changed
     * @param callback
     */
    device(callback) {
        for (const [trigger, node] of Object.entries(this.changes)) {
            node.addEventListener(trigger, () => {
                clearTimeout(this.timeout);
                this.timeout = setTimeout(() => {
                    callback(trigger);
                }, 100 );
            }, { passive: true });
        }
    }
};



