/**
 * Extend vue base ...
 * @param vue
 * @param callback
 */

export default (vue, callback) => {
    vue.set = (path,value) => {
        let a = path.split('.');
        let o = vue.ctx.scope;
        while (a.length - 1) {
            let n = a.shift()
            if (!(n in o)) o[n] = {}
            o = o[n]
        }
        o[a[0]] = value;


        //eval('vue.ctx.scope.' + o + ' = v;');
    };
    vue.call = (path) => {
        let a = path.split('.');
        let o = vue.ctx.scope;
        while (a.length - 1) {
            let n = a.shift()
            if (!(n in o)) o[n] = {}
            o = o[n]
        }
        o[a[0]]();

//        eval('vue.ctx.scope.' + e + '();');
    };
    if ( typeof callback !== "undefined") {
        callback(vue);
    }
};