/**
 * @ngdoc directive
 * @name vue.directive:vMounted
 * This handles includes for html components
 */

import extend from "../services/extend";

export default (vue) => extend(vue, (vue) => {
    vue.call('mounted');
});