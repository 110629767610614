/**
 * @ngdoc directive
 * @name vue.directive:vComplete
 * @see -
 * @example
 */


export default (vue) => {

    let typing = 0;
    let blurring = 0;
    let source = null;

    vue.el.addEventListener('focus', () => {
        vue.ctx.scope.search[vue.exp].focus = true;
        window.clearTimeout(blurring);
    }, { passive: true });

    vue.el.addEventListener('blur', () => {
        vue.ctx.scope.search[vue.exp].focus = false;
        blurring = window.setTimeout ( () => {
            vue.ctx.scope.search[vue.exp].results = [];
        },  250 );
    }, { passive: true });

    vue.el.addEventListener('keyup', (e) => {
        if ( e.key !== undefined && e.key === 'Escape') {
            vue.ctx.scope.search[vue.exp].results = [];
            window.clearTimeout(typing);
            return false;
        }

        window.clearTimeout(blurring);
        window.clearTimeout(typing);

        source = vue.el.dataset.source + (vue.el.dataset.source.includes("?") ? '&' : '?') + 'q=';

        if ( 2 <= vue.el.value.length) {
            typing = window.setTimeout(() => {
                //console.log("http get: " + source + vue.el.value)
                fetch(source + vue.el.value).then((responses) => {
                    return responses.json();
                }).then((json) => {
                    vue.ctx.scope.search[vue.exp].results = json.results;
                });
            }, 250);
        } else {
            vue.ctx.scope.search[vue.exp].results = [];
        }
    }, { passive: true });

};