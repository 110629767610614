/**
 * This handles device detection
 */

export default class device {
    constructor() {
        this.touch = {
            active: 'ontouchstart' in document.documentElement,
            handler: ('ontouchstart' in document.documentElement ? "touchstart" : "click")
        };
        this.label = window.innerWidth < 768 ? 'mobile' : ( window.innerWidth <= 1023 ? 'tablet' : 'desktop');
        this.mobile = window.innerWidth < 768;
        this.tablet = window.innerWidth >= 768 && window.innerWidth <= 1023;
        this.desktop = window.innerWidth >= 1024;

        this.orientation = window.innerWidth < window.innerHeight ? 'portrait' : 'landscape';
        this.width = window.innerWidth;
        this.height = window.innerHeight;
    }

};



